var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"userOrder"}},[_c('div',[_c('div',{staticClass:"searchBox"},[_c('div',[_c('el-date-picker',{attrs:{"type":"datetimerange","range-separator":_vm.$fanyi(''),"start-placeholder":_vm.$fanyi('开始日期'),"end-placeholder":_vm.$fanyi('结束日期'),"value-format":"yyyy-MM-dd HH:mm:ss","default-time":['00:00:00', '23:59:59']},model:{value:(_vm.value1),callback:function ($$v) {_vm.value1=$$v},expression:"value1"}}),_c('button',{staticClass:"searchBtn",on:{"click":_vm.getData}},[_vm._v(" "+_vm._s(_vm.$fanyi('搜索'))+" ")])],1),_c('span',{staticClass:"getMoneyHistory",on:{"click":function($event){return _vm.$fun.toPage('/user/WithdrawalRecord')}}},[_vm._v(_vm._s(_vm.$fanyi('提现记录')))])]),_c('div',{staticClass:"dataBody"},[_c('el-table',{attrs:{"data":_vm.tableData,"border":"","header-cell-style":{
          'text-align': 'center',
          'font-size': '12px ',
          color: 'black',
          background: '#F0F0F0',
          'line-height': 'l4px !important',
        },"cell-style":{
  'text-align': 'center',
},"cell-class-name":"cellClassName","header-row-class-name":"cellHeadClassName"}},[_c('el-table-column',{attrs:{"type":"index","width":"50","label":"No"}}),_c('el-table-column',{attrs:{"property":"confirm_at","label":_vm.$fanyi('日期'),"width":"160"}}),_c('el-table-column',{attrs:{"property":"order_sn","label":_vm.$fanyi('订单号'),"width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"orderBtn"},[(scope.row.order_sn.substr(0, 1) == 'P')?_c('span',{on:{"click":function($event){return _vm.$fun.toPage(
                  '/deliveryDetails?order_sn=' + scope.row.order_sn
                )}}},[_vm._v(" "+_vm._s(scope.row.order_sn)+" ")]):_c('span',{on:{"click":function($event){return _vm.$fun.toPage(
                  '/orderDetails?type=offer' +
                  '&order_sn=' +
                  scope.row.order_sn
                )}}},[_vm._v(" "+_vm._s(scope.row.order_sn)+" ")])])]}}])}),_c('el-table-column',{attrs:{"property":"bill_type","label":_vm.$fanyi('交易类型'),"width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(_vm.$fanyi(scope.row.bill_type))+" ")])]}}])}),_c('el-table-column',{attrs:{"property":"bill_method","label":_vm.$fanyi('支付方式'),"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(_vm.$fanyi(scope.row.bill_method))+" ")])]}}])}),_c('el-table-column',{attrs:{"property":"pay_remark","label":_vm.$fanyi('明细'),"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_c('el-popover',{attrs:{"placement":"bottom-start","trigger":"click"}},[_c('div',{staticClass:"changesInDetail"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$fanyi('金额变动明细'))+" ")]),(!!scope.row.adjust_detail &&
                      scope.row.adjust_detail.length > 0
                      )?_c('div',_vm._l((scope.row.adjust_detail),function(listItem,listIndex){return _c('div',{key:listIndex,staticClass:"changeList"},_vm._l((listItem),function(item,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(_vm.$fanyi(item))+" ")])}),0)}),0):_c('div',[_c('p',[_vm._v(_vm._s(_vm.$fanyi(scope.row.client_bill_reason)))])]),(scope.row.pay_remark)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$fanyi(scope.row.pay_remark)))])]):_vm._e()]),_c('span',{directives:[{name:"show",rawName:"v-show",value:((scope.row.adjust_detail &&
                    scope.row.adjust_detail.length > 0) ||
                    scope.row.client_bill_reason ||
                    scope.row.pay_remark
                    ),expression:"(scope.row.adjust_detail &&\n                    scope.row.adjust_detail.length > 0) ||\n                    scope.row.client_bill_reason ||\n                    scope.row.pay_remark\n                    "}],staticClass:"detail",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(_vm.$fanyi('明细'))+" ")])])],1)]}}])}),_c('el-table-column',{attrs:{"property":"amount","label":_vm.$fanyi('收支')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v("$"+_vm._s(_vm.$fun.EURNumSegmentation(scope.row.amount)))])]}}])}),_c('el-table-column',{attrs:{"property":"balance","label":_vm.$fanyi('账户余额')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v("$"+_vm._s(_vm.$fun.EURNumSegmentation(scope.row.balance)))])]}}])})],1)],1),_c('div',{staticClass:"allpag"},[_c('span'),_c('el-pagination',{attrs:{"current-page":_vm.form.currentPage,"page-size":10,"page-sizes":[10, 20, 30, 50],"layout":" prev, pager, next, jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }