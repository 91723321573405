<template>
  <div id="userOrder">
    <div>
      <div class="searchBox">
        <div>
          <el-date-picker v-model="value1" type="datetimerange" :range-separator="$fanyi('')"
            :start-placeholder="$fanyi('开始日期')" :end-placeholder="$fanyi('结束日期')" value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
          <button class="searchBtn" @click="getData">
            {{ $fanyi('搜索') }}
          </button>
        </div>
        <span class="getMoneyHistory" @click="$fun.toPage('/user/WithdrawalRecord')">{{ $fanyi('提现记录') }}</span>
        <!-- <span class="exportBtn"
          ><img src="../../../../assets/user-img/commodity/export.png" alt="" />
          {{ $fanyi("导出excel") }}</span
        > -->
      </div>
      <div class="dataBody">
        <el-table :data="tableData" border :header-cell-style="{
          'text-align': 'center',
          'font-size': '12px ',
          color: 'black',
          background: '#F0F0F0',
          'line-height': 'l4px !important',
        }" :cell-style="{
  'text-align': 'center',
}" cell-class-name="cellClassName" header-row-class-name="cellHeadClassName">
          <el-table-column type="index" width="50" label="No">
          </el-table-column>
          <el-table-column property="confirm_at" :label="$fanyi('日期')" width="160"></el-table-column>
          <el-table-column property="order_sn" :label="$fanyi('订单号')" width="200">
            <template slot-scope="scope">
              <div class="orderBtn">
                <span v-if="scope.row.order_sn.substr(0, 1) == 'P'" @click="$fun.toPage(
                  '/deliveryDetails?order_sn=' + scope.row.order_sn
                )
                  ">
                  {{ scope.row.order_sn }}
                </span>
                <span v-else @click="$fun.toPage(
                  '/orderDetails?type=offer' +
                  '&order_sn=' +
                  scope.row.order_sn
                )
                  ">
                  {{ scope.row.order_sn }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column property="bill_type" :label="$fanyi('交易类型')" width="140">
            <template slot-scope="scope">
              <div>
                {{ $fanyi(scope.row.bill_type) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column property="bill_method" :label="$fanyi('支付方式')" width="120">
            <template slot-scope="scope">
              <div>
                {{ $fanyi(scope.row.bill_method) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column property="pay_remark" :label="$fanyi('明细')" width="100">
            <template slot-scope="scope">
              <div>
                <el-popover placement="bottom-start" trigger="click">
                  <div class="changesInDetail">
                    <h1>
                      {{ $fanyi('金额变动明细') }}
                    </h1>

                    <div v-if="!!scope.row.adjust_detail &&
                      scope.row.adjust_detail.length > 0
                      ">
                      <div class="changeList" v-for="(listItem, listIndex) in scope.row.adjust_detail" :key="listIndex">
                        <p v-for="(item, index) in listItem" :key="index">
                          {{ $fanyi(item) }}
                        </p>
                      </div>
                    </div>
                    <div v-else>
                      <p>{{ $fanyi(scope.row.client_bill_reason) }}</p>
                    </div>
                    <div v-if="scope.row.pay_remark">
                      <p>{{ $fanyi(scope.row.pay_remark) }}</p>
                    </div>
                  </div>
                  <span v-show="(scope.row.adjust_detail &&
                    scope.row.adjust_detail.length > 0) ||
                    scope.row.client_bill_reason ||
                    scope.row.pay_remark
                    " class="detail" slot="reference">
                    {{ $fanyi('明细') }}
                  </span>
                </el-popover>
              </div>
            </template>
          </el-table-column>

          <el-table-column property="amount" :label="$fanyi('收支')">
            <template slot-scope="scope">
              <div>${{ $fun.EURNumSegmentation(scope.row.amount) }}</div>
            </template>
          </el-table-column>
          <el-table-column property="balance" :label="$fanyi('账户余额')">
            <template slot-scope="scope">
              <div>${{ $fun.EURNumSegmentation(scope.row.balance) }}</div>
            </template></el-table-column>
        </el-table>
      </div>
      <div class="allpag">
        <span></span>
        <el-pagination @current-change="handleCurrentChange" :current-page="form.currentPage" :page-size="10"
          :page-sizes="[10, 20, 30, 50]" layout=" prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCurrentMonthFirst,
  getCurrentMonthLast,
} from '../../../../utlis/date'
export default {
  data() {
    return {
      value1: [],
      form: {
        status: this.$fanyi('待处理'),
        page: 1,
        pageSize: 10,
        searchText: '',
        startTime: '',
        endTime: '',
      },
      tableData: [],
      total: 0,
      tableOption: [], //表格显示哪些选项
      ordersSumList: {}, //商品列表各种类商品数据
    }
  },
  created() {
    // this.form.startTime = getCurrentMonthFirst() + " 00:00:00";
    // this.form.endTime = getCurrentMonthLast() + " 23:59:59";
    // this.value1 = [
    //   getCurrentMonthFirst() + " 00:00:00",
    //   getCurrentMonthLast() + " 23:59:59",
    // ];
    this.getData()
  },
  methods: {
    getData() {

      this.$api
        .capitalBillList({
          status: this.form.status,
          pageSize: this.form.pageSize,
          page: this.form.page,
          startTime: this.value1 ? this.value1[0] : '',
          endTime: this.value1 ? this.value1[1] : '',
          keywords: this.form.searchText,
        })
        .then((res) => {

          if (res.code != 0) return
          this.tableData = res.data.data
          this.total = res.data.total
        })
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getData()
    },
  },
  components: {},
  computed: {},
}
</script>
<style lang="scss" scoped="scoped">
#userOrder {
  @import '../../../../css/mixin';
  width: 100%;
  border-radius: 6px;
  border: 1px solid #e1e1e1;
  overflow: hidden;

  /deep/ .el-pagination__jump,
  /deep/ .el-pagination__total {
    display: none;
  }

  /deep/ .el-table .cell {
    line-height: 14px;
  }

  /deep/ .el-date-editor {
    width: 435px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #e2e2e2;

    .el-input__icon {
      display: none;
    }
  }

  /deep/.el-range-separator {
    width: 3px;
    height: 20px;
    // background-color: pink;
    background: url('../../../../assets/date.png') no-repeat center;
  }

  button {
    cursor: pointer;
  }

  >nav {
    height: 50px;
    display: flex;

    >span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 130px;
      height: 50px;
      background: #e8e8e8;
      border: 1px solid #e1e1e1;
      font-size: 16px;

      color: #000000;
      line-height: 26px;

      cursor: pointer;

      &.active {
        background-color: #ffffff;
        border-bottom: none;
      }
    }
  }

  >div {
    // min-height: 720px;
    background: #ffffff;

    padding: 30px 30px 37px 30px;

    .searchBox {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      justify-content: space-between;

      /deep/.el-date-editor {
        // width: 230px;
        height: 40px;
        margin-right: 20px;
        border-radius: 4px;

        .el-range-input {
          font-size: 14px;
        }

        .el-range-separator {
          width: 50px;
        }
      }

      .searchBtn {
        width: 80px;
        height: 40px;
        background: $homePageSubjectColor;
        border-radius: 4px;

        color: #ffffff;
        font-size: 16px;
      }

      >span {
        font-size: 14px;
        cursor: pointer;
        line-height: 19px;
      }

      .getMoneyHistory {
        min-width: 140px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #ff730b;
        text-align: center;
        line-height: 40px;
        float: left;
        padding: 0 20px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff730b;
      }

      .exportBtn {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
          width: 15px;
          height: 15px;
        }
      }
    }

    .dataBody {}

    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0;

      .el-pagination {
        display: flex;
        align-items: center;
      }

      >a {
        display: inline-block;
        border: 1px solid #dcdfe6;
        height: 28px;
        // background-color: white;
        text-align: center;
        padding: 0 10px;
        font-size: 13px;
        line-height: 28px;
        border-radius: 0;
      }

      /deep/.btn-prev {
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        border-radius: 20px;
        padding: 0;
        margin-right: 30px;

        .el-icon {
          font-size: 15px;
        }
      }

      /deep/.btn-next {
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        border-radius: 20px;
        padding: 0;
        margin-left: 30px;

        .el-icon {
          font-size: 15px;
        }
      }

      /* 分页被选中的页码样式 */
      /deep/ .el-pager li {
        margin: 0 10px;
        // border-radius: 0;
        min-width: 20px !important;
        font-size: 14px;
        height: 30px;
        color: #999999;
        // box-shadow: 0 0 6px 0 rgba(61, 61, 61, 0.1);

        &:hover {
          color: #ff730b;
        }

        &.active {
          color: #ff730b;
          border-bottom: 2px solid #ff730b;
          width: 20px !important;
        }
      }
    }
  }
}

/deep/.cellHeadClassName {
  font-size: 14px;
  color: #000000;
  line-height: 19px;
}

.allpag {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 25px 0;
}

.el-table .cell div {
  word-break: normal;
}

.orderBtn {
  span {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #ff730b;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.detail {
  font-size: 14px;

  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #698cff;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.changesInDetail {
  color: black;

  h1 {
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;

    margin-bottom: 10px;
  }

  .changeList {
    margin-bottom: 15px;

    p {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>
